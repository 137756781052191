<template>
    <div
        class="query-form"
        :class="{
            'button-right-center': buttonAlign === 'right',
            'button-bottom-center': buttonAlign === 'bottom',
        }"
    >
        <el-form
            ref="form"
            class="query-form__form"
            :model="form"
            :inline="inline"
            :label-position="labelPosition"
            :label-width="labelWidth"
            style="margin-bottom: 20px"
        >
            <el-row v-for="row in rows" :key="row">
                <el-col
                    v-for="item in getRowFields(row)"
                    :key="item.field"
                    :span="24 / columns"
                >
                    <el-form-item
                        :label="item.label"
                        :prop="item.field"
                        label-position="left"
                        label-align="left"
                        :label-width="item.labelWidth || labelWidth"
                    >
                        <template v-if="item.component === 'input'">
                            <el-input 
                                v-model.lazy="form[item.field]"
                                :placeholder="item.placeholder"
                            ></el-input>
                            <template v-if="item.extra && item.extra.length > 0">
                                <div v-for="(ei, idx) in item.extra" :key="item.field + 'extra-div-ei-' + idx" 
                                    :style="{display: 'flex', width: 'auto'}">
                                    <label class="el-form-item__label" :style="{whiteSpace: 'nowrap', width: labelWidth, marginLeft: '20px'}">
                                        {{ ei.label }}
                                    </label>
                                    
                                    <el-input :style="{width: '202px'}"
                                        :key="item.field + 'input-ei-' + idx"
                                        :ref="item.field + 'extra' +  ei.field"
                                        v-model="form[ei.field]"
                                        :label="ei.label"
                                        :placeholder="ei.placeholder"
                                        style="margin-left: 10px"
                                    >
                                    </el-input>
                                </div>
                            </template>
                        </template>
                        <template v-else-if="item.component === 'number'">
                            <el-input-number
                                v-model.lazy="form[item.field]"
                                :placeholder="item.placeholder"
                            ></el-input-number>
                        </template>
                        <template v-else-if="item.component === 'select'">
                            <el-select :clearable="true"
                                v-if="!item.flat"
                                v-model.lazy="form[item.field]"
                                :placeholder="item.placeholder"
                                @change="handleSelectChange(item.field, $event)"
                            >
                                <el-option
                                    v-for="option in item.options"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                            <template v-if="!item.flat">
                                <el-select :clearable="true"
                                    v-for="(ei, idx) in item.extra"
                                    :key="item.field + 'noFlatSelect-ei-' + idx"
                                    :ref="item.field + 'extra' +  ei.field"
                                    v-model="form[ei.field]"
                                    :placeholder="ei.placeholder"
                                    style="margin-left: 10px"
                                >             
                                    <el-option
                                        v-for="option in ei.options"
                                        :key="option.value"
                                        :label="option.label"
                                        :value="option.value"
                                    ></el-option>
                                </el-select>
                            </template>

                            <el-radio-group
                                v-if="item.flat"
                                v-model.lazy="form[item.field]"
                            >
                                <el-radio-button
                                    v-for="option in item.options"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-radio-button>
                            </el-radio-group>

                        </template>
                        <template v-else-if="item.component === 'custom-select'">                            
                            <el-link type="primary" :underline="false" style="margin-right: 10px;"
                            @click="doCustomSelect(item)">{{ item.placeholder }}</el-link>
                            <label style="margin-right: 10px">{{ item.selection.join('，') }}</label>
                        </template>
                        <template v-else-if="item.component === 'datePicker'">
                            <el-date-picker
                                v-model.lazy="form[item.field]"
                                :type="item.dateType || 'date'"
                                :placeholder="item.placeholder"
                            ></el-date-picker>
                        </template>
                        <template
                            v-else-if="item.component === 'dateTimePicker'"
                        >
                            <el-date-picker
                                v-model.lazy="form[item.field]"
                                type="datetime"
                                :placeholder="item.placeholder"
                            ></el-date-picker>
                        </template>
                        <template v-else-if="item.component === 'timePicker'">
                            <el-time-picker
                                v-model.lazy="form[item.field]"
                                :placeholder="item.placeholder"
                            ></el-time-picker>
                        </template>
                        <template v-else-if="item.component === 'el-cascader'">
                            <component
                                :is="item.component"
                                v-model="form[item.field]"
                                :placeholder="item.placeholder"
                                :options="item.options"
                                :props="item.props"
                                :show-all-levels="item.showAllLevels"
                                @change="handleChange(item.field, $event)"
                            ></component>
                            <component
                                v-for="(ei, idx) in item.extra"
                                :is="ei.component"
                                :key="'ei' + idx"
                                v-model="form[ei.field]"
                                :placeholder="ei.placeholder"
                                :options="ei.options"
                                :props="ei.props"
                                :show-all-levels="ei.showAllLevels"
                                style="margin-left: 10px"
                            ></component>
                        </template>
                        
                        <el-button
                            v-if="item.withQueryBtn"
                            type="primary"
                            :disabled="actDisabled"
                            style="margin-left: 50px"
                            @click="handleSubmit"
                            >立即查询</el-button
                        >
                        <el-button
                            v-if="item.withResetBtn"
                            style="margin-left: 20px"
                            @click="handleReset"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row v-if="hasBtnBar">
            <el-col
                :span="24"
                :class="{
                    'query-form__button-container': true,
                    'query-form__button-container--align-bottom':
                        buttonAlign === 'bottom',
                    'query-form__button-container--align-right':
                        buttonAlign === 'right',
                }"
            >
                <el-button type="primary" :disabled="actDisabled" @click="handleSubmit">查询</el-button>
                <el-button @click="handleReset">重置</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        fields: {
            type: Array,
            required: true,
            default: () => [],
        },
        inline: {
            type: Boolean,
            default: true,
        },
        hasBtnBar: {
            type: Boolean,
            default: true,
        },
        labelPosition: {
            type: String,
            default: "right",
        },
        labelWidth: {
            type: String,
            default: "130px",
        },
        buttonAlign: {
            type: String,
            default: "right",
            validator: (value) => {
                return ["bottom", "right"].indexOf(value) !== -1;
            },
        },
        columns: {
            type: Number,
            default: 1,
        },
        actDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {},
        };
    },
    mounted() {
        this.generateFormData();
    },
    computed: {
        rows() {
            return Math.ceil(this.fields.length / this.columns);
        },
    },
    methods: {
        getFormData() {
            return this.form || {};
        },
        generateFormData() {
            (this.fields || []).forEach(item => {
                this.$set(this.form, item.field, '');
                (item.extra || []).forEach(ei => {
                    this.$set(this.form, ei.field, null);
                });
            });
        },
        setSelection(field, selection) {
            console.log('setSelection->' + field);
            console.log('setSelection->selection=', selection);
            let target = this.fields.find((item) => item.field === field);
            if (target) {
                this.form[field] = selection;
            }
        },
        setOptions(field, options) {
            console.log('setOptions->' + field);
            console.log('setOptions->options=', options);
            let target = this.fields.find((item) => item.field === field);
            if (target) {
                target.options = options;
            }
        },
        setExtraOptions(field, extraField, options, defaultValue) {
            console.log('setExtraOptions->' + field + ', extraField=' + extraField);
            console.log('setExtraOptions->options=', options);
            let target = this.fields.find((item) => item.field === field);
            if (target) {
                let targetExtra = (target.extra || []).find((item) => item.field === extraField);
                if (targetExtra) {             
                    targetExtra.options = [...options];
                    this.form[extraField] = null;
                }
            }
        },
        getRowFields(row) {
            const start = (row - 1) * this.columns;
            const end = row * this.columns;
            return this.fields.slice(start, end);
        },
        handleSubmit() {
            this.$emit("submit", this.form);
        },
        handleChange(field, value) {
            const target = this.fields.find((item) => item.field === field);
            if (
                target &&
                typeof target.changeHandler === "function"
            ) {
                target.changeHandler(value);
            }
        },
        handleSelectChange(field, value) {
            const target = this.fields.find((item) => item.field === field);
            if (
                target &&
                typeof target.changeHandler === "function"
            ) {
                target.changeHandler(value);
            }
        },
        handleReset() {
            this.$refs.form.resetFields();
            (this.fields || []).forEach(item => {
                if (item.component === 'custom-select')
                    item.selection = [];
                (item.extra || []).forEach(ei => {
                    this.form[ei.field] = null;
                });
            });
            this.$emit("reset");
        },

        doCustomSelect(item) {
            console.log('doCustomSelect-->item=', item);
            this.form[item.field] = item.selection;
            this.$emit("customSelect", this.form);
        }
    },
};
</script>

<style scoped>
.el-form-item {
    display: flex;
    align-items: center;
}

.el-form-item__label {
    width: 100px; /* 设置标签宽度 */
}

.el-form-item__content {
    flex: 1; /* 让输入控件自适应宽度 */
}

::v-deep .el-form--inline .el-form-item__content {
    display: flex !important;
}

.el-form-item__label--bold {
    font-weight: bold;
}

.el-form-item__content--bg {
    background-color: #f5f5f5;
}

.query-form.button-bottom-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.query-form.button-right-center {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: center; */
}

.query-form-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.query-form__button-container {
    text-align: right;
    margin-top: 20px;
}

.query-form__button-container--align-bottom {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.query-form__button-container--align-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

::v-deep .el-radio-button {
    margin-left: 10px;
}

::v-deep .el-radio-button:first-child {
    margin-left: 0;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: #007EF2 !important;
    box-shadow: none;
    height: 40px;
    background: #edf4ff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #007EF2 !important;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
    border-left: none;
}

.el-radio-button {
    border-left: none;
}

::v-deep .el-radio-button__inner {
  border: none;
}
::v-deep .el-button--primary {
  background-color: #007EF2;
  border-color: #007EF2;
}
::v-deep .el-link.el-link--primary{
    color: #007EF2;
}

</style>
